// 引入cookie-js
import Cookie from 'js-cookie'

import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [{
  path: '/',
  component: () => import('@/views/index.vue')
}, {
  path: '/screen',
  meta: {
    title: '大屏',
    requireAuth: true
  },
  component: resolve => require(['@/views/screen/index'], resolve)
  // component: resolve => require(['@/views/manage/point/pointLine111'], resolve)
},
{
  path: '/manage',
  component: () => import('@/views/manage/index.vue'),

  children: [{
    path: '/manage/',
    meta: {
      title: '管理后台',
      requireAuth: true
    },
    component: resolve => require(['@/views/manage/home'], resolve)
  },

  {
    path: '/manage/admin/lists',
    meta: {
      title: '管理人员',
      requireAuth: true
    },
    component: resolve => require(['@/views/manage/admin/lists'], resolve)
  },
  {
    path: '/manage/admin/group',
    meta: {
      title: '管理角色',
      requireAuth: true
    },
    component: resolve => require(['@/views/manage/admin/group'], resolve)
  },
  {
    path: '/manage/admin/menu',
    meta: {
      title: '管理权限',
      requireAuth: true
    },
    component: resolve => require(['@/views/manage/admin/menu'], resolve)
  }, {
    path: '/manage/user/lists',
    meta: {
      title: '用户管理',
      requireAuth: true
    },
    component: resolve => require(['@/views/manage/user/lists'], resolve)
  }, {
    path: '/manage/organization/lists',
    meta: {
      title: '项目组织',
      requireAuth: true
    },
    component: resolve => require(['@/views/manage/organization/lists'], resolve)
  }, {
    path: '/manage/point/lists',
    meta: {
      title: '项目管理',
      requireAuth: true
    },
    component: resolve => require(['@/views/manage/point/lists'], resolve)
  },
  {
    path: '/manage/point/pointLine',
    meta: {
      title: '喷淋管理',
      requireAuth: true
    },
    component: resolve => require(['@/views/manage/point/pointLine'], resolve)
  },
  {
    path: '/manage/point/camera',
    meta: {
      title: '喷淋管理',
      requireAuth: true
    },
    component: resolve => require(['@/views/manage/point/camera'], resolve)
  },
  {
    path: '/manage/equipment/lists',
    meta: {
      title: '设备管理',
      requireAuth: true
    },
    component: resolve => require(['@/views/manage/equipment/lists'], resolve)
  }, {
    path: '/manage/equipment/logo',
    meta: {
      title: '设备日志',
      requireAuth: true
    },
    component: resolve => require(['@/views/manage/equipment/log'], resolve)
  },
  {
    path: '/manage/journal/resource',
    meta: {
      title: '资源消耗日志',
      requireAuth: true
    },
    component: resolve => require(['@/views/manage/journal/resource'], resolve)
  },
  {
    path: '/manage/journal/warning',
    meta: {
      title: '预警告警日志',
      requireAuth: true
    },
    component: resolve => require(['@/views/manage/journal/warning'], resolve)
  },
  {
    path: '/manage/journal/weather',
    meta: {
      title: '气象日志',
      requireAuth: true
    },
    component: resolve => require(['@/views/manage/journal/weather'], resolve)
  },
  {
    path: '/manage/equipment/class',
    meta: {
      title: '设备类别',
      requireAuth: true
    },
    component: resolve => require(['@/views/manage/equipment/class'], resolve)
  }, {
    path: '/manage/setting/region',
    meta: {
      title: '设置',
      requireAuth: true
    },
    component: resolve => require(['@/views/manage/setting/region'], resolve)
  },
  {
    path: '/manage/setting/whole',
    meta: {
      title: '全局设置',
      requireAuth: true,
    },
    component: resolve => require(['@/views/manage/setting/whole'], resolve)
  },
  {
    path: '/manage/setting/help',
    meta: {
      title: '设备帮助',
      requireAuth: true
    },
    component: resolve => require(['@/views/manage/setting/help'], resolve)
  }, 
  ]
}]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

// 导航守卫
router.beforeEach((to, from, next) => {
  if (to.meta.requireAuth == true) { // 判断该路由是否需要登录权限
    let admin = Cookie.get('admin')
    if (admin === undefined || admin === 'e30=' || !admin) { // cookie是否存在
      next({
        path: '/' // 跳转到登录界面
      })
    } else {
      next()
    }
  } else {
    next()
  }
})

export default router
